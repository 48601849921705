import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from "react-oidc-context";
import 'primereact/resources/themes/lara-light-indigo/theme.css';
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)
const oidcConfig = {
  authority: process.env.REACT_APP_AUTHORITY ,
  client_id: 'web_app',
  redirect_uri: window.location.origin + '/meterreadings/6692',
  response_type: 'code',
  scope: 'openid profile email offline_access', // Adjust this scope according to your needs
};
root.render(
  <React.StrictMode>
    <AuthProvider {...oidcConfig}>
      <App />
    </AuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
