// src/App.tsx
import React, {useContext, useRef} from 'react';
import Dashboard from './Dashboard';

import { useAuth } from "react-oidc-context";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faUser} from "@fortawesome/free-solid-svg-icons";
import MeterReadingsChart from "./MeterReadingsChart";
import {Route, BrowserRouter, Routes, Navigate, RouteProps} from 'react-router-dom';
import { PrimeReactProvider } from 'primereact/api';
import {Menu} from "primereact/menu";
import {Welcome} from "./Welcome";
import ProfileImage from "./ProfileImage";



const App: React.FC = () => {
  const auth = useAuth();
  const menuLeft = useRef<Menu>(null);

  const items = [
    {
      label: 'User',
      items: [
        {
          label: 'Manage Account',
          icon: 'pi pi-refresh'
        },
        {
          label: 'Logout',
          icon: 'pi pi-upload',
          command: () => {
            void auth.signoutRedirect();
          }
        }
      ]
    }
  ];
  switch (auth.activeNavigator) {
    case "signinSilent":
      return  <div className="App">
        <div className="content">Signing you in...</div></div>
        ;
    case "signoutRedirect":
      return  <div className="App">
        <div className="content">Signing you out...</div>
      </div>
  }

  if (auth.isLoading) {
    return <div className="App"><div className="content">Loading...</div></div>
  }

  if (auth.error) {
    return <div className="content">Oops... {auth.error.message}</div>;
  }

  let userplace
  if(auth.isAuthenticated){
    userplace= <span onClick={(event) => menuLeft?.current?.toggle(event)}><ProfileImage name={auth.user?.profile.name||'XX'} /></span>
  } else{
    userplace=<span className="icon"><FontAwesomeIcon icon={faUser} onClick={(event) =>auth.signinRedirect()}/></span>
  }
  console.log(auth.user)
  return (

    <div className="App">
      <PrimeReactProvider>
        <div className="header">
          <Menu model={items} popup ref={menuLeft} id="popup_menu_left" />

          <div><img className="navbar-brand" src="https://kwiqly.com/favicon.png"/><span className={'title'}>kInsight</span> <span className="navbar-version">0.0.1</span></div>
          <div>
            {userplace}
          </div>
        </div>
        <div className="content">
          <BrowserRouter>
            <Routes>

              <Route path="/meterreadings/:meterKey" element={
                auth.isAuthenticated ? (
                  <MeterReadingsChart />
                ) : (
                  <Navigate to="/" replace />
                )
              } />

              <Route path="/" element={<Welcome />} />


              {/* Other routes can also be defined here */}
            </Routes>
          </BrowserRouter>
        </div>
        <div className="footer">
          © 2024 kWIQly GmbH
        </div>
      </PrimeReactProvider>
    </div>
  );


}

export default App;

