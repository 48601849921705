import { Button } from "primereact/button";
import {useAuth} from "react-oidc-context";

export function Welcome() {
  const auth = useAuth();
  let login=auth.isAuthenticated?<span/>:<Button  onClick={(event) =>auth.signinRedirect()}>login</Button>;
  return (<div>

    {login}
  </div>);
}
